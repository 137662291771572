import React, {useCallback, useEffect, useRef, useState} from "react";
import "@glideapps/glide-data-grid/dist/index.css";
import axios from "../../utils/authAxios";
import {useUndoRedo} from "../../utils/undoRedo";
import {RiFileExcel2Line} from "react-icons/ri";
import {MdDownload} from "react-icons/md";
import {downloadXlsx} from "../../utils/fileDownload";
import {DataEditor} from "@glideapps/glide-data-grid";
import {useLayer} from "react-laag";
import styles from './Spreadsheet.module.css'
import {Button, Checkbox, Input, List} from "antd";
import {GrSearch} from "react-icons/gr";

const Spreadsheet = ({extractedData}) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [showSearch, setShowSearch] = useState(false)

    const containerRef = useRef(null);
    const gridRef = useRef(null);

    useEffect(() => {
        const preferredColumnOrder = JSON.parse(localStorage.getItem('columns')) ?? []
        const newColumn = extractedData.map(v => v.columns)
        const parseColumns = (columns) => (columns.map((c, i) => c.map(v => ({
            ...v,
            id: `${i}-${v.key}`,
            hasMenu: true,
            menuIcon: 'dots'
        }))))
        const sortColumn = (column) => ([...column].sort((a, b) => {
            if (a.key < b.key) return -1
            if (a.key > b.key) return 1
            return 0
        }))
        if (JSON.stringify(preferredColumnOrder.map(v => sortColumn(v))) === JSON.stringify(newColumn.map(v => sortColumn(v)))) setColumns(parseColumns(preferredColumnOrder))
        else setColumns(parseColumns(newColumn))
    }, [extractedData]);

    const getData = useCallback(([col, row]) => {
        const rowData = rows[row];
        const key = columns[currentTab][col]?.key
        const cellData = rowData?.[key] || ''
        return {
            kind: 'text',
            allowOverlay: true,
            data: cellData,
            displayData: cellData,
            readonly: !rowData._extraction_id
        }
    }, [currentTab, columns, rows])
    const onColumnResize = useCallback((column, newSize) => {
        setColumns(prevState => {
            const index = prevState[currentTab].findIndex(ci => ci.title === column.title)
            const currentColumn = [...prevState[currentTab]]
            currentColumn[index].width = newSize
            prevState.splice(currentTab, 1, currentColumn)
            return [...prevState]
        })
    }, [currentTab]);
    const onColumnMoved = useCallback((startIndex, endIndex) => {
        setColumns(prevState => {
            const newCols = [...prevState[currentTab]];
            const [toMove] = newCols.splice(startIndex, 1);
            newCols.splice(endIndex, 0, toMove);
            prevState.splice(currentTab, 1, newCols)
            localStorage.setItem('columns', JSON.stringify(prevState.map(c => c.map(v => ({
                key: v.key,
                title: v.title
            })))))
            return [...prevState];
        });
    }, [currentTab]);

    const eventDataDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = {event_type: 'update', event_data: {'_key': key}};
                return target[key];
            }
        });
    }
    const onCellsEditedHandler = useCallback((newValues) => {
        const eventData = eventDataDefault()
        newValues.forEach(newValue => {
            const [col, row] = newValue.location
            const value = newValue.value
            const currentColumn = columns[currentTab][col]
            const currentRow = rows[row]
            if (currentRow._extraction_id) { // disable history update
                currentRow[currentColumn.key] = value.data
                eventData[currentRow._key].extraction_id = currentRow._extraction_id
                eventData[currentRow._key].event_data[currentColumn.key] = value.data
            }
        })
        const events = Object.values(eventData)
        events.length && axios.put('/v1/events', Object.values(eventData)).then()
    }, [currentTab, columns, rows]);

    const [showMenu, setShowMenu] = useState(undefined)
    const filteringCheckboxOptionsRef = useRef({})
    const [filteringCheckboxTempOptions, setFilteringCheckboxTempOptions] = useState([])
    const [filteringCheckboxTempValues, setFilteringCheckboxTempValues] = useState([])
    const [filteringCheckboxValues, setFilteringCheckboxValues] = useState({})
    const checkAll = !new Set(filteringCheckboxTempOptions).difference(new Set(filteringCheckboxTempValues)).size
    const {renderLayer, layerProps} = useLayer({
        isOpen: showMenu !== undefined,
        triggerOffset: 4,
        onOutsideClick: () => setShowMenu(undefined),
        trigger: {
            getBounds: () => ({
                left: showMenu?.bounds.x ?? 0,
                top: showMenu?.bounds.y ?? 0,
                right: (showMenu?.bounds.x ?? 0) + (showMenu?.bounds.width ?? 0),
                bottom: (showMenu?.bounds.y ?? 0) + (showMenu?.bounds.height ?? 0),
                width: showMenu?.bounds.width ?? 0,
                height: showMenu?.bounds.height ?? 0,
            }),
            getParent: () => containerRef.current
        },
        overflowContainer: false,
        placement: "bottom-end",
        auto: true,
        possiblePlacements: ["bottom-start", "bottom-end"],
    });
    const filteringRowOptionsDefault = (obj = {}) => {
        return new Proxy(obj, {
            get: (target, key) => {
                if (!(key in target)) target[key] = []
                return target[key];
            }
        });
    }
    const onHeaderMenuClick = useCallback((col, bounds) => {
        setShowMenu({col, bounds})
        const key = columns[currentTab][col].key
        const filteringRowOptions = filteringRowOptionsDefault()
        extractedData[currentTab].rows.forEach((value, index) => {
            const v = value[key]
            filteringRowOptions[v].push(index)
        })
        filteringCheckboxOptionsRef.current.new = filteringRowOptions
        const tempOptions = Object.keys(filteringRowOptions)
        const tempValues = filteringCheckboxValues.sheet === currentTab && filteringCheckboxValues.column === key ? filteringCheckboxValues.values : tempOptions
        setFilteringCheckboxTempOptions([...tempValues.sort(), ...Array.from(new Set(tempOptions).difference(new Set(tempValues))).sort()])
        setFilteringCheckboxTempValues(tempValues)
    }, [extractedData, currentTab, columns, filteringCheckboxValues])
    const onFilteringInputChange = useCallback(e => setFilteringCheckboxTempOptions(Object.keys(filteringCheckboxOptionsRef.current.new).filter(v => v.toLowerCase().includes(e.target.value.toLowerCase())).sort()), [filteringCheckboxOptionsRef])
    const onFilteringCheckboxChange = useCallback(list => setFilteringCheckboxTempValues(prevState => Array.from(new Set(prevState).difference(new Set(filteringCheckboxTempOptions)).union(new Set(list)))), [filteringCheckboxTempOptions])
    const onFilteringCheckboxAllChange = useCallback(e => setFilteringCheckboxTempValues(e.target.checked ? filteringCheckboxTempOptions : []), [filteringCheckboxTempOptions])
    const onFilterApply = useCallback(() => {
        const filter = Array.from(new Set(filteringCheckboxTempOptions).intersection(new Set(filteringCheckboxTempValues)))
        filteringCheckboxOptionsRef.current.applied = filteringCheckboxOptionsRef.current.new
        setFilteringCheckboxValues(Object.keys(filteringCheckboxOptionsRef.current.new).length === filter.length ? {} : {
            sheet: currentTab,
            column: columns[currentTab][showMenu.col].key,
            values: filter
        })
        setShowMenu(undefined)
    }, [currentTab, columns, showMenu, filteringCheckboxOptionsRef, filteringCheckboxTempOptions, filteringCheckboxTempValues])

    const onSort = useCallback(key => {
        setRows(prevState => {
            const sortKey = columns[currentTab][showMenu.col].key
            return [...prevState.sort((a, b) => {
                if (a[sortKey] < b[sortKey]) return key === 'asc' ? -1 : 1
                if (a[sortKey] > b[sortKey]) return key === 'asc' ? 1 : -1
                return 0
            })]
        })
        setShowMenu(undefined)
    }, [columns, currentTab, showMenu])

    const {gridSelection, onCellsEdited, onGridSelectionChange} = useUndoRedo(gridRef, getData, onCellsEditedHandler);

    useEffect(() => {
        const onKeyDown = (event) => {
            if (event.code === "KeyF" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                event.stopPropagation();
                setShowSearch(v => !v);
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown)
        };
    }, []);

    useEffect(() => {
        if (filteringCheckboxValues.values && filteringCheckboxValues.sheet === currentTab) {
            const filteredRows = []
            filteringCheckboxValues.values.forEach(v => filteringCheckboxOptionsRef.current.applied?.[v].forEach(i => filteredRows.push(extractedData[currentTab].rows[i])))
            setRows(filteredRows)
        } else setRows(extractedData[currentTab]?.rows ?? [])
    }, [extractedData, currentTab, filteringCheckboxOptionsRef, filteringCheckboxValues]);


    return (
        <div ref={containerRef} className={'space-y-3 h-full flex flex-col'}>
            <div className="flex space-x-3">
                {extractedData.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentTab(index)}
                        className={`${
                            currentTab === index ? "font-medium bg-gray-200 text-blue-500" : "bg-gray-100 text-gray-600"
                        } px-4 h-9 rounded-lg text-sm flex-none`}>
                        {item['sheet_name']}
                    </button>
                ))}
                <div className={'flex-grow'}/>
                <div className={'flex flex-none'}>
                    <button onClick={() => downloadXlsx(extractedData)}
                            className="px-2 py-1 bg-gray-100 rounded-lg text-xs text-green-700 flex space-x-2 items-center transition hover:bg-gray-200">
                        <RiFileExcel2Line className="w-4 h-4"/>
                        <p>Export to Excel</p>
                        <MdDownload className="w-4 h-4"/>
                    </button>
                </div>
            </div>
            <div className="flex-1 overflow-auto text-gray-700 space-y-8 pb-2">
                <div className="h-full text-xs font-WantedSans" style={{minHeight: "300px"}}>
                    <DataEditor ref={gridRef}
                                columns={columns[currentTab] ?? []}
                                getCellContent={getData}
                                rows={rows.length}
                                rowMarkers={'number'}
                                rowHeight={24}
                                headerHeight={26}
                                getCellsForSelection={true}
                                width={'100%'}
                                height={'100%'}
                                onPaste={false}
                                showSearch={showSearch}
                                onSearchClose={() => setShowSearch(false)}
                                gridSelection={gridSelection ?? undefined}
                                onGridSelectionChange={onGridSelectionChange}
                                onHeaderMenuClick={onHeaderMenuClick}
                                onColumnResize={onColumnResize}
                                onColumnMoved={onColumnMoved}
                                onCellsEdited={onCellsEdited}/>
                    <div id="portal" style={{position: 'fixed', left: 0, top: 0, zIndex: 9999}}/>
                    {showMenu !== undefined && renderLayer(
                        <div {...layerProps} style={{...layerProps.style}} className={styles.menu}>
                            <List className={'mb-2'}
                                  dataSource={[{'key': 'asc', 'text': 'Sort ▲'}, {'key': 'desc', 'text': 'Sort ▼'}]}
                                  renderItem={(item) => <List.Item className={styles.item}
                                                                   onClick={() => onSort(item.key)}>{item.text}</List.Item>}/>
                            <div className={'px-2 mb-2 flex flex-col'}>
                                <Input onChange={onFilteringInputChange} suffix={<GrSearch/>}/>
                                <Checkbox className={'ml-auto'} checked={checkAll}
                                          onChange={onFilteringCheckboxAllChange}>Select all</Checkbox>
                                <Checkbox.Group style={{height: '10rem'}}
                                                className={`${styles.checkboxGroup} py-1 overflow-y-auto flex flex-col flex-nowrap border`}
                                                value={filteringCheckboxTempValues}
                                                options={filteringCheckboxTempOptions.map(value => ({
                                                    label: value || '(Blank)',
                                                    value: value
                                                }))}
                                                onChange={onFilteringCheckboxChange}/>
                            </div>
                            <div className={'flex flex-row justify-end space-x-3 px-2'}>
                                <Button type={'primary'} onClick={onFilterApply}>OK</Button>
                                <Button onClick={() => setShowMenu(undefined)}>Close</Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Spreadsheet;
