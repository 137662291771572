import React, {useEffect, useState} from "react";
// import { RiFileExcel2Line } from "react-icons/ri";
// import { MdDownload } from "react-icons/md";
// import { downloadXlsx } from "../../utils/fileDownload";
// import Members from "../../components/Dashboard/Members";
import axios from "../../utils/authAxios";
import Spreadsheet from "../../components/Dashboard/Spreadsheet";
// import DropComponentAutoGenerate from "../../../components/dropComponent/DropComponentAutoGenerate";

const Dashboard = () => {
    const [extractedData, setExtractedData] = useState([]);

    useEffect(() => {
        axios
            .get(`/v1/extractions`)
            .then((response) => {
                setExtractedData(response.data);
            })
            .catch((e) => console.log(e));

        return () => {
        };
    }, []);

    const Card = ({title, child}) => (
        <div className="p-3 rounded-lg bg-gray-100">
            <p className="mb-2 font-medium text-gray-500 text-xs">{title}</p>
            <div className="rounded-lg text-sm text-blue-600">{child}</div>
        </div>
    );

    return (
        <div className="w-full h-full overflow-auto text-gray-700 relative">
            <div className="w-full h-full rounded-lg px-3 space-y-3 flex flex-col">
                <div className="flex pt-3 space-x-3">
                    <Card title="Forward your email to:"
                          child={<div className="rounded-lg text-sm text-blue-600">auto@mailtocell.com</div>}/>
                </div>
                <div className={"flex-1"}>
                    <Spreadsheet extractedData={extractedData}/>
                </div>
                {/* <div className="flex space-x-4 w-full">
          <div className="w-full p-3 rounded-lg border">
            <p className="mb-2 font-medium text-gray-500 text-sm h-7">History</p>

            <div className="bg-white rounded-lg text-sm text-blue-600">
              <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium bg-gray-100 rounded-lg mb-2">
                <div className="w-48 h-full text-gray-500">Status</div>
                <div className="w-48 h-full text-gray-500">Date</div>
              </div>
              <div className="space-y-1">
                <div className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 font-medium rounded-lg">
                  <div className="w-48 h-full text-gray-500 flex items-center space-x-2">
                    <VscLoading className="text-gray-400 animate-spin w-4 h-4" />
                    <p>Processing</p>
                  </div>
                  <div className="w-48 h-full text-gray-500">August 26, 2024 - 08:32 AM</div>
                </div>
                {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => (
                  <div key={index} className="px-3 py-2 text-xs flex min-w-48 items-center space-x-3 rounded-lg">
                    <div className="w-48 h-full text-gray-500 flex items-center space-x-2">
                      <FaCheck className="w-3.5 h-3.5 text-green-600" />
                      <p className="text-gray-400">Added to spreadsheet</p>
                    </div>
                    <div className="w-48 h-full text-gray-500">August 26, 2024 - 08:32 AM</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
            </div>
        </div>
    );
};

export default Dashboard;
