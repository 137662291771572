import React, { useState } from "react";
import Partners from "../components/Landing/Partners";
import FAQ from "../components/Landing/FAQ";
import Footer from "../components/Footer";
import emailjs from "@emailjs/browser";
import SEOMetaTag from "../utils/SEOMetaTag";
import { Button, Input, Select } from "antd";

const ContactUs = () => {
  const [isLoading, setLoading] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;

  const ContactSection = () => {
    const [formData, setFormData] = useState({
      name: "",
      companyName: "",
      position: "",
      email: "",
      teamSize: null,
      how: "",
      message: "",
    });

    const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs
        .send("service_6qujhcp", "template_drad1sg", formData, "kwxwUGy9ULeJ6f--7")
        .then(() => {
          setFormData({
            name: "",
            companyName: "",
            position: "",
            email: "",
            teamSize: null,
            how: "",
            message: "",
          });
          alert(`Inquiry sent - We'll get back to you within 1 business day.`);
        })
        .catch((e) => {
          console.error(e);
          // Open an error notification
          alert(`Something went wrong - please try again.`);
        })
        .finally(() => setLoading(false));
    };

    return (
      <div className="w-full md:py-12 py-3 flex justify-center text-gray-700">
        <div className="max-w-screen-xl w-full h-full flex items-center px-4 md:space-x-16 md:px-16 md:space-y-0 pt-24 justify-center">
          <div className="w-full max-w-screen-sm">
            <h2 className="text-5xl mb-6 font-semibold">Contact us</h2>
            <h3 className="mb-6 text-gray-500 break-keep">
              Contact the Mailtocell Team to learn how our experts can help you automate email-to-spreadsheet conversion
              and streamline your workflow.
            </h3>
            <h3 className="mb-6 text-gray-500 break-keep">
              Need product support? Email us at <span className="text-blue-600">info@mailtocell.com</span> for
              assistance with Mailtocell.
            </h3>
            <form className="space-y-5 w-full">
              <Input value={formData.name} placeholder={"Name"} id="name" onChange={handleChange} className="h-11" />
              <div className="flex w-full flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
                <Input
                  value={formData.companyName}
                  placeholder={"Company name"}
                  id="companyName"
                  onChange={handleChange}
                  className="h-11 w-full"
                />
                <Input
                  value={formData.position}
                  placeholder={"Job title"}
                  id="position"
                  onChange={handleChange}
                  className="h-11 w-full"
                />
              </div>
              <Input
                placeholder={"Work email"}
                value={formData.email}
                type="email"
                id="email"
                onChange={handleChange}
                className="h-11 w-full"
              />
              <Select
                placeholder={"Number of team members interested in using Mailtocell"}
                id="teamSize"
                onChange={(value) => handleChange({ target: { id: "teamSize", value: value } })}
                className="h-11 max-w-xs md:max-w-full w-full"
                value={formData.teamSize}
              >
                <Option value="Number of team members interested in using Mailtocell">
                  {"Number of team members interested in using Mailtocell"}
                </Option>
                <Option value="1~2">1~2</Option>
                <Option value="3~4">3~4</Option>
                <Option value="5~10">5~10</Option>
                <Option value="11+">11+</Option>
              </Select>
              <Input
                placeholder={"How did you hear about us?"}
                id="how"
                value={formData.how}
                onChange={handleChange}
                className="h-11 w-full"
              />
              <TextArea
                rows={4}
                multiple
                id="message"
                value={formData.message}
                placeholder={"Tell us about your case"}
                onChange={handleChange}
                className="h-11 w-full"
              />

              <Button
                onClick={handleSubmit}
                type="primary"
                loading={isLoading}
                className="h-12 bg-blue-500 w-full text-white text-lg font-medium"
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex items-center flex-col relative pt-20 bg-neutral-50">
      <div className="w-full z-20 flex flex-col items-center">
        <SEOMetaTag
          title="Contact Us - Mailtocell"
          description="Get in touch with Mailtocell for inquiries, support, or assistance with automating email-to-spreadsheet conversions. We're here to help you streamline your workflow."
          keywords="Mailtocell contact, email automation support, spreadsheet automation help, email-to-spreadsheet assistance, Mailtocell customer service"
          url="https://www.mailtocell.com/contact"
        />
        <ContactSection />
        <Partners />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
