import XLSX from "xlsx-js-style";

export const downloadXlsx = (data) => {
    const wb = XLSX.utils.book_new();

    data.map(v => {
        const keys = v.columns.map(column => column.key)
        const rows = v.rows.map(row => (keys.map(key => ({v: row[key]}))))
        const headers = v.columns.map(column => ({v: column.title}))
        const sheet = XLSX.utils.aoa_to_sheet([headers, ...rows])
        // sheet["!cols"] = [{ wch: 20 }, { wch: 30 }]
        XLSX.utils.book_append_sheet(wb, sheet, v.sheet_name)
        return null
    })
    XLSX.writeFile(wb, `${new Date().toLocaleString()}.xlsx`);
};
